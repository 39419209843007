"use client";
import { SignIn, useUser } from "@clerk/nextjs";
import { Box } from "@mui/material";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useDarkMode } from "~/context/DarkmodeContext";
import { pusherClient } from "@trigify/services/pusher";
import { generateClerkTheme } from "~/styles/theme";

export default function Page() {
  const { mode } = useDarkMode();
  const { user } = useUser();

  useEffect(() => {
    void user?.reload();
    posthog.reset();
    pusherClient.unsubscribe(`private-${user?.id}`);
  }, [user?.id, user?.reload]);

  return (
    <Box
      sx={{
        fontFamily: "Inter, sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <SignIn appearance={generateClerkTheme(mode)} />
    </Box>
  );
}
