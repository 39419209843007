"use client";
import { createTheme } from "@mui/material";
import type { PaletteMode, ThemeOptions } from "@mui/material";
import { grey, pink } from "@mui/material/colors";
import { Square } from "@untitled-ui/icons-react";

export const generateClerkTheme = (
  mode: PaletteMode,
  overrides?: {
    showCreateOrgButton?: boolean;
  },
) => ({
  variables: {
    colorBackground: mode === "dark" ? "#0C111D" : "#fff",
    colorText: mode === "dark" ? "#fff" : "#213547",
    colorPrimary: "#D5118E",
  },
  elements: {
    formButtonPrimary: {
      borderRadius: 50,
    },
    socialButtonsBlockButton: {
      background: mode === "light" ? "#fff" : "#161B26",
      color: mode === "dark" ? "#fff" : "#213547",
    },
    userButtonPopoverActionButton: {
      color: mode === "dark" ? "#fff" : "#213547",
      "&:hover, &:focus, &:active": {
        color: "#D5118E",
      },
    },
    selectButton: {
      color: mode === "dark" ? "#fff" : "#213547",
      border: `1px solid ${mode === "dark" ? "#131415" : "#D0D5DD"}`,
    },
    userButtonPopoverCard: {
      background: mode === "dark" ? "#0C111D" : "#fff",
      color: mode === "dark" ? "#fff" : "#213547",
    },
    organizationSwitcherInvitationAcceptButton: {
      color: mode === "dark" ? "#fff" : "#213547",
      background: "#D5118E",
      "&:hover, &:focus, &:active": {
        background: "#D5118E",
      },
    },
    otpCodeFieldInput: {
      background: mode === "dark" ? "#0C111D" : "#fff",
      color: mode === "dark" ? "#fff" : "#213547",
      borderWidth: "1px !important",
      borderColor: "#eee",
      "&:focus, &:hover": {
        borderWidth: "1px !important",
        borderColor: "#D5118E",
      },
    },
    button: {
      borderRadius: 50,
    },

    organizationPreview: {
      color: mode === "dark" ? "#fff" : "#213547",
    },
    organizationSwitcherTriggerIcon: {
      color: mode === "dark" ? "#fff" : "#213547",
    },
    organizationProfile: {
      background: mode === "dark" ? "#0C111D" : "#fff",
      color: mode === "dark" ? "#fff" : "#213547",
    },
    organizationSwitcherPopoverActionButton: {
      color: mode === "dark" ? "#fff" : "#213547",
    },
    organizationSwitcherPopoverActionButton__createOrganization: {
      color: mode === "dark" ? "#fff" : "#213547",
      display: overrides?.showCreateOrgButton ? "flex" : "none",
    },
    notificationBadge: {
      background: mode === "dark" ? "#D5118E" : "#fff",
      color: mode === "dark" ? "#fff" : "#D5118E",
      border: `1px solid ${mode === "dark" ? "#D5118E" : "#fff"}`,
    },
    tabButton: {
      borderRadius: 4,
      color: mode === "dark" ? "#fff" : "#213547",
    },
    navbarButton: {
      color: mode === "dark" ? "#fff" : "#213547",
    },
  },
});

export const getPaletteColors = (color: PaletteMode) => ({
  background: {
    default: color === "dark" ? "#161B26" : "#F9FAFB",
    paper: color === "dark" ? "#0C111D" : "#fff",
  },
  primary: {
    main: "#D5118E",
  },
  success: {
    main: "#17B26A",
  },
  text: {
    primary: color === "dark" ? "#fff" : "#101828",
    secondary: color === "dark" ? "#94969C" : "#667085",
  },
  pink: {
    ...pink,
    50: "#FCCEEE",
  },
  tertiary: {
    main: "#915DFF",
    dark: "#6941C6",
    light: "#F7F3FF",
  },
  secondary: {
    main: "#FF7F0A",
  },
  info: {
    main: "#915DFF",
  },
});

export const getThemedComponents = (color: PaletteMode): ThemeOptions["components"] => ({
  MuiCheckbox: {
    defaultProps: {
      icon: <Square />,
    },
  },
  // MuiChip: {
  //   variants: [
  //     {
  //       props: { variant: "outlined" },
  //       style: {
  //         color: "#D5118E",
  //         borderRadius: 50,
  //         borderColor: "#D5118E",
  //       },
  //     },
  //     {
  //       props: { variant: "filled" },
  //       style: {
  //         color: "#D5118E",
  //         borderRadius: 50,
  //         fontWeight: 500,
  //         backgroundColor: pink[50],
  //         border: `1px solid ${pink[200]}}`,
  //       },
  //     },
  //   ],
  //   defaultProps: {
  //     variant: "outlined",
  //   },
  // },
  MuiTextField: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          borderRadius: 8,
          background: color === "dark" ? "#131415" : "#F9FAFB",
        },
      },
    ],
    styleOverrides: {
      root: {
        // focused box shadow
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#D5118E",
          borderWidth: 1,
          boxShadow: "0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        },
      },
    },
  },

  MuiTabs: {
    defaultProps: {
      // @ts-expect-error -- removed bottom border
      indicatorColor: "",
    },
    styleOverrides: {
      root: {
        "& .MuiButtonBase-root": {
          borderRadius: 4,
          textTransform: "none",
          fontWeight: 500,
        },
        "& .MuiTab-root": {
          padding: "0 8px",
          margin: "0 8px",
          minHeight: "unset",
          height: "40px",
        },
        "& .Mui-selected": {
          "& svg": {
            color: "#D5118E",
          },
          border: `1px solid ${grey[300]}`,
          fontWeight: 600,
          color: color === "light" ? "#182230" : "#fff",
          minHeight: "unset",
          height: 40,
          backgroundColor: color === "light" ? "#fff" : "#0C111D",
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "& .MuiSelect-select": {
          background: color === "dark" ? "#131415" : "#fff",
        },
        height: 44,
        minHeight: "unset",
        borderRadius: 8,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: 8,
        border: `1px solid ${color === "dark" ? "#131415" : "#D0D5DD"}`,
        padding: 4,
        boxShadow: "0px 12px 16px -4px #10182814",
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          width: 32,
          height: 32,
        },

        "& .Mui-active": {
          color: "#7F56D9",
        },
        padding: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "& :hover": {
          borderRadius: 8,
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          color: color === "light" ? "#344054" : "#fff",
          maxHeight: 40,
          borderRadius: 50,
          borderColor: "#D0D5DD",
        },
      },
    ],
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        minWidth: 100,
        borderRadius: 50,
        textTransform: "none",
      },
    },
  },
});

export const trigifyTheme = createTheme({
  gradient: `linear-gradient(45deg, #D5118E 38.72%, #F9A558 91.21%)`,
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1536,
      md: 1200,
      sm: 900,
      xs: 0,
    },
  },
  sizes: {
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "10px",
      xlarge: "12px",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      lineHeight: "38px",
      fontSize: "60px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "48px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "36px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
    },
    h6: {
      fontSize: "24px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
});
