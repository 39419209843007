import PusherClient from "pusher-js";
export const pusherClient = new PusherClient(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, {
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
    authEndpoint: "/api/pusher/auth",
    channelAuthorization: {
        endpoint: "/api/pusher/auth",
        transport: "ajax",
    },
});
import PusherServer from "pusher";
let pusherInstance = null;
export const getPusherInstance = () => {
    if (!pusherInstance) {
        pusherInstance = new PusherServer({
            appId: process.env.PUSHER_APP_ID,
            key: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
            secret: process.env.PUSHER_APP_SECRET,
            cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
            // useTLS: true,
        });
    }
    return pusherInstance;
};
